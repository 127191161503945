<template>
  <div class="navbar-container d-flex content align-items-center">
    <h2 class="content-header-title float-left pr-1 mb-0 text-colorBlack">
      {{ $route.meta.pageTitle }}
    </h2>
    <ul class="nav navbar-nav d-xl-none">
      <li class="nav-item">
        <b-link class="nav-link" @click="toggleVerticalMenuActive">
          <feather-icon icon="MenuIcon" size="21" class="text-primary" />
        </b-link>
      </li>
    </ul>

    <b-navbar-nav class="nav align-items-center ml-auto">
      <notification-dropdown class="nav align-items-end ml-auto" />
      <b-nav-item-dropdown
        right
        toggle-class="d-flex align-items-center dropdown-user-link"
        class="dropdown-user"
      >
        <template #button-content>
          <div class="d-sm-flex d-none user-nav">
            <p class="user-name font-weight-bolder mb-0">
              {{ getLoggedInUser ? getLoggedInUser.username : "" }}
            </p>
          </div>
          <b-avatar
            size="40"
            variant="light-primary"
            badge
            :src="getLoggedInUser ? getLoggedInUser.profile_image : ''"
            class="badge-minimal"
            badge-variant="success"
          />
        </template>

        <!-- <b-dropdown-item-button button-class="w-100">
          <b-link :to="{ name: 'Profile' }">
            <feather-icon size="16" icon="UserIcon" class="mr-50" />
            <span>Profile</span></b-link
          >
        </b-dropdown-item-button>

        <b-dropdown-divider />

        <b-dropdown-item-button button-class="w-100" @click="logoutButtonClick">
          <feather-icon size="16" icon="GlobeIcon" class="mr-50" />
          <span>Language</span>
        </b-dropdown-item-button>

        <b-dropdown-divider /> -->

        <b-dropdown-item-button button-class="w-100" @click="logoutButtonClick">
          <feather-icon size="16" icon="LogOutIcon" class="mr-50" />
          <span>Logout</span>
        </b-dropdown-item-button>
      </b-nav-item-dropdown>
    </b-navbar-nav>
  </div>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import NotificationDropdown from "./NotificationDropdown.vue";

export default {
  components: {
    NotificationDropdown,
  },
  props: {
    toggleVerticalMenuActive: {
      type: Function,
      default: () => {},
    },
  },
  methods: {
    ...mapActions({ logout: "appData/logout" }),
    async logoutButtonClick() {
      try {
        const res = await this.logout();
        if (res.status === 204) {
          this.$router.push({ name: "Login" });
        }
      } catch (error) {
        console.log(error);
      }
    },
  },
  computed: {
    ...mapGetters({
      hasRole: "appData/hasRole",
      getLoggedInUser: "appData/getLoggedInUser",
    }),
  },
};
</script>

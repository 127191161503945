<template>
  <b-nav-item-dropdown
    class="dropdown-notification mr-25"
    menu-class="dropdown-menu-media"
    right
  >
    <template #button-content>
      <feather-icon
        badge-classes="bg-danger"
        class="text-body"
        icon="BellIcon"
        size="21"
      />
    </template>

    <!-- Header -->
    <li class="dropdown-menu-header">
      <div class="dropdown-header d-flex">
        <h4 class="notification-title mb-0 mr-auto">Notifications</h4>
        <b-badge pill variant="light-primary"> New </b-badge>
      </div>
    </li>

    <!-- Notifications -->
    <vue-perfect-scrollbar
      :settings="perfectScrollbarSettings"
      class="scrollable-container media-list scroll-area h-300px"
      tagname="li"
    >
      <!-- Account Notification -->
      <b-link class="notification_hover">
        <div>
          <p
            class="media-heading m-0 p-50 d-flex justify-content-between align-items-center"
          >
            <span class="font-weight-bolder text-white d-block"> </span>
            <span class="d-block pr-2">
              <feather-icon class="text-body" icon="MailIcon" size="21" />
            </span>
            <span class="d-block pr-2">
              <feather-icon class="text-body" icon="XCircleIcon" size="21" />
            </span>
          </p>

          <p class="p-50 m-0 text-white"></p>
        </div>

        <hr class="my-0" />
      </b-link>
    </vue-perfect-scrollbar>

    <!-- Cart Footer -->
    <li class="dropdown-menu-footer">
      <b-button
        v-ripple.400="'rgba(255, 255, 255, 0.15)'"
        variant="primary"
        block
      >
        Notifications</b-button
      >
    </li>
  </b-nav-item-dropdown>
</template>
<script>
import {
  BNavItemDropdown,
  BBadge,
  BMedia,
  BLink,
  BAvatar,
  BButton,
  BFormCheckbox,
} from "bootstrap-vue";
import VuePerfectScrollbar from "vue-perfect-scrollbar";
import { mapGetters, mapActions } from "vuex";
import Ripple from "vue-ripple-directive";
import util from "@/util.js";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";

export default {
  mixins: [util],
  components: {
    BNavItemDropdown,
    BBadge,
    BMedia,
    BLink,
    BAvatar,
    VuePerfectScrollbar,
    BButton,
    BFormCheckbox,
    ToastificationContent,
  },
  data() {
    return {
      title: "abc",
      description: "abc description",
      isRead: true,
      notifications: [],
      perfectScrollbarSettings: {
        maxScrollbarLength: 60,
        wheelPropagation: false,
      },
      intervalId: null,
    };
  },
  directives: {
    Ripple,
  },
  async mounted() {},
  methods: {
    ...mapActions({}),
  },
  computed: {
    ...mapGetters({}),
  },
};
</script>

<style scoped>
li {
  list-style-type: none;
}
.notification_hover :hover {
  background-color: #161d31;
}
.h-300px {
  height: 300px !important;
}
</style>
